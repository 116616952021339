import request from '@/utils/request';

export type resoureType = 'LIGHTNODE_ECS' | 'OWN_EIP' | 'OWN_DISK' | 'FLOW_PACKET' | 'PAN_ECS';
export type functionNameType = 'NORMAL';

export type RegionAndZoneDetailInfoType = {
  resourceType?: resoureType;
  functionName?: functionNameType;
  _?: number;
} & Record<string, any>;

export type ImageListType = {
  page: number;
  pageSize: number;
  regionCode: string;
  zoneCode: string;
  filter?: string; // JSON 字符串
  _?: number;
} & Record<string, any>;

export const checkCaptcha = (data: any) => request.post('/api/support/v1/captcha/checkCaptcha.do', { data });

export const getAppKey = (params: any) => request.get('/api/support/v1/captcha/getAppKey.do', { params });

// 获取地区
export const fetchRegionAndZoneDetailInfo = (params: RegionAndZoneDetailInfoType) => {
  return request.get('/api/site/sku/getRegionAndZoneDetailInfo.do', {
    params,
  });
};

// 获取充值地址
export const fetchRechargeUrl = (data: any) => {
  return request.post('/api/payment-app/v1/recharge/online', { data });
};

// 充值信息
export const fetchRechargeById = (id: string) => {
  return request.get(`/api/payment-app/v1/recharge/${id}`);
};

// 获取镜像信息
export const fetchImageList = (params: ImageListType) => {
  return request.get('/api/ec/image/getImageList.do', {
    params,
  });
};

// 获取平台信息
export const fetchPlatform = (params: any) => request('/userApi/user/platform/info.do', params);

// 获取未登录平台信息
export const fetchCommonPlatformInfo = (params: any) => request('/userApi/common/platform/info.do', { params });

// 获取商品信息映射
export const fetchProductConfigEnum = (params: any) => request('/api/site/sku/getProductConfigEnum.do', { params });

// 获取vnc地址
export const fetchVNC = (params: { _t?: number | string; ecsResourceUUID: string }) =>
  request.get('/api/ec/ecs/getInstanceVncInfo', { params });

// vnc重启实例
export const restartVNCServer = (data: any) => request.post('/api/ec/ecs/restart', { data });

// 获取文章
export const fetchArticle = (params: any) => request('/api/operation/v1/article/console', { params });

// 获取是否为第一个订单
export const fetchIsFirstOrder = (params: any) => {
  return request.get('/api/order/prodorder/isFirstOrder.do', { params });
};
// 资源使用率提醒
export const getflowNotifySelf = () => {
  return request.get('/api/ec/ip/flowNotify/self.do');
};
// 资源使用率提醒开关
export const postflowNotifySelf = (data: { id: string; flowNotifyEnable: boolean }) => {
  return request.post('/api/ec/ip/flowNotify/self.do', { data });
};
// 获取实例状态
export const fetchInstanceStatus = (params: { taskUUID: string | number; _?: number; [key: string]: any }) =>
  request.get('/api/ec/asynctask/getResult.do', { params });
// 获取实例信息
export const fetchInstanceList = (params: any) => {
  return request.get('/api/ec/ecs/getInstanceList.do', { params });
};
