import { useState } from 'react';
import { history } from 'umi';
import '/node_modules/flag-icons/css/flag-icons.min.css';

declare const window: any;
const whiteList = [
  'ynuf.aliapp.org',
  'cf.aliyun.com',
  'diablo.alibaba.com',
  'g.alicdn.com',
  'af.alicdn.com',
  'aeu.alicdn.com',
];
export const qiankun = {
  // 注册子应用信息
  excludeAssetFilter: (assetUrl: string) => {
    return whiteList.some((url) => assetUrl.indexOf(url) !== -1);
  },
  async: true,
};
// qiankun 父子状态传递
export function useQiankunStateForSlave() {
  const [masterState, setMasterState] = useState({
    user: {},
  });

  return {
    masterState,
    setMasterState,
  };
}

export function onRouteChange({
  matchedRoutes,
  location,
}: {
  matchedRoutes: { route: { title: string } }[];
  location: Location;
}) {
  // 单页应用gtag
  const platformName = sessionStorage.getItem('platformName');
  window.gtag?.('set', 'page_path', location.pathname);
  window.gtag?.('event', 'page_view');
  if (location.pathname.indexOf('/product/') === -1 && matchedRoutes.length) {
    if (matchedRoutes[matchedRoutes.length - 1].route.title) {
      window.document.title = `${matchedRoutes[matchedRoutes.length - 1].route.title}${
        platformName ? ` - ${platformName}` : ''
      }`;
    }
  }
  if (window.frameElement) {
    // 页面单点劫持处理
    window.location.href = 'https://console.lightnode.com';
  }
}

// 国际化
export const locale = {
  getLocale() {
    if (window.location?.search) {
      // 跳转语言设置 ie 11不支持断言 只能这样不够精确
      const reg = new RegExp('zh-CN|zh-HK|en-US|fr-FR|vi-VN|ru-RU|ja-JP|es-ES|ne-NP');
      const match = window.location.search.match(reg);
      if (match?.[0]) {
        localStorage.setItem('umi_locale', match?.[0]);
        //设置完清除lang，避免切换其他语言不生效
        history.replace(`${history.location.pathname}${history.location.search?.replace(`lang=${match[0]}`, '')}`);
        return match[0];
      }
    }
    const lang = localStorage.getItem('umi_locale');
    // 初始化设置
    if (!lang) {
      localStorage.setItem('umi_locale', lang || 'en-US');
    }

    return lang || 'en-US';
  },
  // 设置语言时子应用页面会刷新且重新挂载
  setLocale({ lang, updater }: any) {
    updater();
    localStorage.setItem('umi_locale', lang);
  },
};
