import account from './account/es-ES.json';
import affiliate from './affiliate/es-ES.json';
import component from './component/es-ES.json';
import menus from './menus/es-ES.json';
import user from './users/es-ES.json';
import global from './global/es-ES.json';
import support from './support/es-ES.json';
import result from './result/es-ES.json';
import finance from './finance/es-ES.json';
import region from './region/es-ES.json';
import request from './request/es-ES.json';

// 因不知名原因，当存在menu和user文件夹时，i18n-ally插件没反应，故修改文件名为menus,users

const titleMenu = Object.entries(menus).reduce((pre: object, [key, value]: [string, string]) => {
  // 添加统一后缀
  return {
    ...pre,
    [`title.${key}`]: `${value} - LightNode`,
  };
}, {});

export default {
  ...account,
  ...affiliate,
  ...component,
  ...menus,
  ...titleMenu,
  ...user,
  ...global,
  ...support,
  ...result,
  ...finance,
  ...region,
  ...request,
};
