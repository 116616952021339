import account from './account/ne-NP.json';
import affiliate from './affiliate/ne-NP.json';
import component from './component/ne-NP.json';
import menus from './menus/ne-NP.json';
import user from './users/ne-NP.json';
import global from './global/ne-NP.json';
import support from './support/ne-NP.json';
import result from './result/ne-NP.json';
import finance from './finance/ne-NP.json';
import region from './region/ne-NP.json';
import request from './request/ne-NP.json';
// 因不知名原因，当存在menu和user文件夹时，i18n-ally插件没反应，故修改文件名为menus,users

const titleMenu = Object.entries(menus).reduce((pre: object, [key, value]: [string, string]) => {
  // 添加统一后缀
  return {
    ...pre,
    [`title.${key}`]: `${value} - LightNode`,
  };
}, {});

export default {
  ...account,
  ...affiliate,
  ...component,
  ...menus,
  ...titleMenu,
  ...user,
  ...global,
  ...support,
  ...result,
  ...finance,
  ...region,
  ...request,
};
