// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/root/.npm/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'@/layouts/BlankLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'@/layouts/UserLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "title": "menu.user.login",
            "path": "/user/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__login' */'/root/.npm/src/pages/User/login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "menu.user.register",
            "path": "/user/register",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__register' */'/root/.npm/src/pages/User/register'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "menu.user.reset",
            "path": "/user/reset",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__resetPassword' */'/root/.npm/src/pages/User/resetPassword'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/oauth/callback/google",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__ouathCallback' */'/root/.npm/src/pages/User/ouathCallback'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/register/callback/google",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__registerCallback' */'/root/.npm/src/pages/User/registerCallback'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "menu.user.twoFAuth",
            "path": "/user/twoFAuth",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__twoFAuth' */'/root/.npm/src/pages/User/twoFAuth'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "menu.user.resetTwoFAuth",
            "path": "/user/resetTwoFAuth",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__resetTwoFAuth' */'/root/.npm/src/pages/User/resetTwoFAuth'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "menu.user.setPassword",
            "path": "/user/setPassword",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__setPassword' */'/root/.npm/src/pages/User/setPassword'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/vnc",
        "title": "title.menu.vnc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__vnc' */'/root/.npm/src/pages/vnc'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'@/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/action",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__NoMenuLayout' */'@/layouts/NoMenuLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/action/improve-info",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__improveUserInfo' */'/root/.npm/src/pages/User/improveUserInfo'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'@/layouts/BasicLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/",
                "redirect": "/product",
                "exact": true
              },
              {
                "path": "/product",
                "microApp": "product-app",
                "microAppProps": {
                  "autoSetLoading": true,
                  "wrapperClassName": "m-productApp"
                },
                "exact": false,
                "component": (() => {
          const { getMicroAppRouteComponent } = umiExports;
          return getMicroAppRouteComponent({ appName: 'product-app', base: '/', masterHistoryType: 'browser', routeProps: {'settings':{},'autoSetLoading':true,'wrapperClassName':'m-productApp'} })
        })()
              },
              {
                "path": "/finance/record",
                "title": "title.menu.financial.record",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Finance__record__index' */'/root/.npm/src/pages/Finance/record/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/finance/billing",
                "title": "title.menu.financial.billing",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Finance__billing__index' */'/root/.npm/src/pages/Finance/billing/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/support/ticket",
                "title": "title.menu.support.tickets",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Support__ticket' */'/root/.npm/src/pages/Support/ticket'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/support/ticket/detail",
                "title": "title.menu.support.ticketsDetail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Support__ticket__detail' */'/root/.npm/src/pages/Support/ticket/detail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/support/ticket/add",
                "title": "title.menu.support.addTickets",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Support__ticket__add' */'/root/.npm/src/pages/Support/ticket/add'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/affiliate/link-code",
                "title": "title.menu.affiliate.linkCode",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Affiliate__linkCode' */'/root/.npm/src/pages/Affiliate/linkCode'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/affiliate/stats",
                "title": "title.menu.affiliate.affiliateStats",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Affiliate__stats' */'/root/.npm/src/pages/Affiliate/stats'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/finance/payment",
                "title": "title.menu.financial.payment",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__payment' */'/root/.npm/src/pages/Account/payment'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "title": "title.menu.account.profile",
                "path": "/account/profile",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__profile' */'/root/.npm/src/pages/Account/profile'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "title": "title.menu.account.authentication",
                "path": "/account/authentication",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__authentication' */'/root/.npm/src/pages/Account/authentication'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "title": "title.menu.account.authentication.add",
                "path": "/account/authentication/add",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__authentication__add' */'/root/.npm/src/pages/Account/authentication/add'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "title": "title.menu.account.authentication",
                "path": "/account/notifications",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__notifications' */'/root/.npm/src/pages/Account/notifications'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/account/ssh",
                "title": "title.menu.account.ssh",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__ssh' */'/root/.npm/src/pages/Account/ssh'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/account/ssh/detail",
                "title": "title.menu.account.sshDetail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__ssh__detail' */'/root/.npm/src/pages/Account/ssh/detail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/result/pay-result",
                "title": "title.menu.result.payResult",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Result__payResult' */'/root/.npm/src/pages/Result/payResult'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/messages/record",
                "title": "title.menu.financial.record",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Messages__record__index' */'/root/.npm/src/pages/Messages/record/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ban",
                "title": "title.menu.ban",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Ban__ban__index' */'/root/.npm/src/pages/Ban/ban/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/root/.npm/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
